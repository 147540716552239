import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { StyledField } from "../../ui/StyledField";
import { sendFBConversionEvent } from "../../../services/pixel";
import {
  connectAccountToSubscription,
  login,
  register,
  registerFree,
  validateAdminPassword,
} from "../../../services/firebase";
import {
  promo_purchase_success,
  promo_signup_success,
  Subscribe_Account_AccountCreationSuccess,
} from "../../../services/mixpanel/mixpanel";
import * as Yup from "yup";
import { mobileSize } from "../../../util/variables";
import { AppLoadingButton, AppText } from "../../ui";
import { navigate } from "gatsby";
import { setOneSignalAttributes } from "../../../services/onesignal";
import { emailAssertion, passwordAssertion } from "../../../util/yup-schemas";
import { triggerCustomEvent } from "../../../services/google-tag-manager";
import { GTM_CUSTOM_EVENT } from "../../../util/types";

const userSchema = Yup.object().shape({
  email: emailAssertion,
  password: passwordAssertion,
  code: Yup.string().required(),
});

interface AccountFormProps {
  subscriptionType: string;
  data: {
    email: string;
    customer_id: string;
    subscription_id: string;
    product: string;
  } | null;
  free: boolean;
}

interface FormikValuesProps {
  email: string;
  password: string;
  code: string;
}

export const AccountForm: React.FC<AccountFormProps> = ({ subscriptionType, data, free }) => {
  const [loading, setLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const handleSubmit = async (values: FormikValuesProps) => {
    const { email, password, code } = values;
    setLoading(true);

    let uid = "";
    // create a Firebase user
    try {
      if (free) {
        const adminResponse = await validateAdminPassword(code, email);
        if (!adminResponse) {
          return;
        }

        const response = await registerFree({ email, password, code });
        uid = response.user.uid;
      } else if (data) {
        const { email, customer_id, subscription_id, product } = data;
        if (showLogin) {
          const res = await login({ email, password });
          if (!res) {
            throw new Error("no user found");
          }

          uid = res.user.uid;
          await connectAccountToSubscription({
            uid,
            customer_id,
            fetch_token: subscription_id,
          });
        } else {
          const response = await register({
            email,
            password,
            customer_id,
            subscription_type: subscriptionType,
            fetch_token: subscription_id,
            product,
          });
          uid = response.user.uid;
        }
      }

      // send tracking to Facebook Pixel and MixPanel
      const promo = localStorage.getItem("promo");
      if (promo) {
        // tracks if the user came from a special promotional link
        promo_signup_success(promo);
        promo_purchase_success();
        localStorage.removeItem("promo");
      }

      const yearly_price = 99.99;
      const monthly_price = 15.99;
      const free_price = 0;
      const value =
        subscriptionType === "yearly" ||
        subscriptionType === "discount" ||
        subscriptionType === "coupon"
          ? yearly_price
          : subscriptionType === "monthly"
          ? monthly_price
          : free_price;
      sendFBConversionEvent({ subscription_type: subscriptionType, value, uid });
      triggerCustomEvent(GTM_CUSTOM_EVENT.COMPLETE_REGISTRATION);
      await setOneSignalAttributes(uid, email);
      await Subscribe_Account_AccountCreationSuccess(email, uid);
      setLoading(false);
      navigate("/subscription/create-account/complete");
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: data?.email ?? "",
          password: "",
          code: "",
        }}
        validationSchema={userSchema}
        onSubmit={handleSubmit}
      >
        {(formik: FormikProps<FormikValuesProps>) => (
          <Form style={{ paddingBottom: 24 }}>
            <Title>Create Account</Title>
            <FormContainer>
              <Header>
                {free ? (
                  <>
                    Create your <Blue>free</Blue> Imprint account
                  </>
                ) : showLogin ? (
                  <>
                    <Blue>Login</Blue> to your Imprint account.
                  </>
                ) : (
                  <>
                    Next, <Blue>set a password</Blue> for your Imprint account.
                  </>
                )}
              </Header>
              <StyledField
                disabled={Boolean(data) || !free}
                value={data?.email}
                name="email"
                type="email"
              />
              <StyledField value={formik.values.password} name="password" type="password" />
              {free && (
                <StyledField
                  value={formik.values.code}
                  name="code"
                  type="password"
                  placeholder="Code"
                />
              )}
              <HideDiv showDesktop>
                <AppLoadingButton
                  disabled={
                    !formik.values.password || !formik.values.code || !!formik.errors.password
                  }
                  loading={loading}
                  type="submit"
                >
                  Submit
                </AppLoadingButton>
              </HideDiv>
              {!free && (
                <AppText color="8D8794" fontSize={12} style={{ marginTop: 12 }}>
                  {showLogin ? "Don't have an account?" : "Already have an account?"}{" "}
                  <Blue style={{ cursor: "pointer" }} onClick={() => setShowLogin(!showLogin)}>
                    {showLogin ? "Create one" : "Log in"}
                  </Blue>
                </AppText>
              )}
            </FormContainer>
            <HideDiv showMobile>
              <AppLoadingButton
                disabled={
                  !formik.values.password || !formik.values.code || !!formik.errors.password
                }
                loading={loading}
                type="submit"
                style={{ border: `2px solid ${theme.WHITE_COLOR}` }}
              >
                Submit
              </AppLoadingButton>
            </HideDiv>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const Title = styled.h1`
  font-size: 35px;
  font-weight: 600;
  color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    font-size: 24px;
    font-weight: 700;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 90vw;
  padding: 80px;
  margin: auto;
  margin-top: 31px;
  border-radius: 16px;
  background-color: #fafafa;

  @media ${mobileSize} {
    padding: 40px 20px;
    min-height: 400px;
    margin-bottom: 24px;
  }
`;

const Header = styled.h2`
  max-width: 350px;
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 20px;
  text-align: center;
  color: ${theme.BLACK_COLOR};

  @media ${mobileSize} {
    text-align: left;
  }
`;

const Blue = styled.span`
  color: ${theme.PRIMARY_COLOR};
`;

const HideDiv = styled.div<{ showDesktop?: boolean; showMobile?: boolean }>`
  display: ${props => (props.showDesktop ? "block" : "none")};
  @media ${mobileSize} {
    display: ${props => (props.showDesktop ? "none" : "block")};
  }
`;
